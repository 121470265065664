@import url('./assets/styles/video-react.css');

:root {
  --primary-font-family: 'Lato', sans-serif;
  --secondary-font-family: 'Libre Baskerville', serif;
  --bg-color: #4c562d;
  --primary-text-color: #e5e1d6;
  --accent-color: #eac766;
  --secondary-accent-color: #29a098;
  --gutter-width: 0.5rem;
}

html {  
  scroll-behavior: smooth;
}

body {
  background: var(--bg-color);
  color: var(--primary-text-color);
  font-family: var(--primary-font-family);
}

a {
  color: var(--accent-color);
}

h1, h2, h3, h4, h5, h6, table caption {
  font-family: var(--secondary-font-family);
  font-weight: normal;
}

p, address, th, td {
  font-size: 1.25rem;
  line-height: 1.2;
}

.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.banner {
  background-size: cover;
  background-position: center;
  height: 100vh;
}

.banner:after {
  content: "";
  clear: both;
  display: table;
}

.banner.banner__no-bg {
  height: auto;
}

.banner-inner {
  padding: 1rem 0.5rem 0.5rem;
  background: rgba(0, 0, 0, 0.5);
  float: right;
}

.banner h1 {
  margin: 0 0 0.5rem;
  font-size: 3rem;
  color: var(--primary-accent-color);
}

.banner h2 {
  margin: 0 0 0.5rem;
}

.banner nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
  border-top: 1px solid var(--primary-text-color);
}

.banner nav ul li {
  display: inline-block;
  margin-right: 1rem;
}

.banner nav ul li a {
  display: block;
  padding: 0.5rem;
  font-size: 1.5rem;
  color: var(--primary-text-color);
}

.banner .content-wrapper {
  margin: 0;
  width: 100%;
}

.content-wrapper {
  width: 95%;
  max-width: 900px;
  margin: 2rem auto;
}

.content-wrapper img {
  display: block;
  width: 100%;
  height: auto;
}

.image-banner {
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
  margin: 2rem 0;
}

.image-banner:last-child {
  margin-bottom: 0;
}

.image-banner .content-wrapper {
  margin: 0;
  padding: 2rem;
}

@media (min-width: 48rem) {
  .two-col {
    display: flex;
    > * {
      width: 50%;
      &:first-child {
        padding-right: var(--gutter-width);
      }
      &:last-child {
        padding-left: var(--gutter-width);
      }
    }
  }
}

.image-full-width img,
.image-full-width video {
  width: 100%;
  height: auto;
}

.progressMainWrapper {
  background: gray;
  height: 3px;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
}

.progressMainWrapper img {
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: auto;
  transform: translateY(-100%);
  filter: invert(1);
}

.progressMainStyle {
  height: 15px;
  width: 15px;
  position: absolute;
  top: 0;
  background-color: #00cc83;
}

.video-react .video-react-big-play-button {
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.no-wrap {
  white-space: nowrap;
}

table {
  text-align: left;
  border-collapse: collapse;
}

table tr th, table tr td {
  padding: 0.5rem;
  border-bottom: 1px solid var(--primary-text-color);
}

table tr:first-of-type th, table tr:first-of-type td {
  padding-top: 0;
}